




import { CreateElement, VNode } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import DistanceColumnHeader from '../counters/distance.column.header.vue';
import Table, { ITableColumn } from '../tables/generator.vue';
import { PlainObject } from '../tables/types';
import CounterRecordsTableColumnImage from './counter-records-table-column-image.vue';
import { CounterRecord, Proximity } from './types';

@Component({ components: { Table } })
export default class CounterRecordsTable extends Vue {
  @Prop({ type: Object, required: true })
  readonly state!: unknown;

  readonly schema: ITableColumn[] = [
    { prop: 'id', width: 100, label_i18n: 'id', formatter: this.formatId },
    { prop: 'image', width: 180, label_i18n: 'image', component: CounterRecordsTableColumnImage },
    { prop: 'faces_count', width: 60, label_i18n: 'faces', align: 'center' },
    { prop: 'silhouettes_count', width: 90, label_i18n: 'silhouettes', align: 'center' },
    { prop: 'cars_count', width: 110, label_i18n: 'cars', align: 'center' },
    {
      prop: 'distance',
      formatter: formatDistance,
      'render-header': this.renderDistanceColumnHeader
    },
    { prop: 'date', width: 160, label_i18n: 'date', formatter: this.formatDate }
  ];

  private renderDistanceColumnHeader(h: CreateElement): VNode {
    return h(DistanceColumnHeader);
  }

  private formatDate({ created_date }: CounterRecord): string {
    return this.$filters.formatDateTime(created_date);
  }

  private formatId({ id }: CounterRecord): string {
    return String(id);
  }

  computeRowClassName({ row }: PlainObject) {
    return (row as { error: boolean }).error ? 'error' : '';
  }
}

function formatDistance({ proximity_min: min, proximity_avg: avg, proximity_max: max }: Proximity): string {
  return `${formatDistanceValue(min)} / ${formatDistanceValue(avg)} / ${formatDistanceValue(max)}`;
}

function formatDistanceValue(coordinate: number | null): string {
  return defined(coordinate) ? String(coordinate) : '-';
}

function defined<T>(candidate: T | null | undefined): candidate is T {
  return candidate != null;
}
