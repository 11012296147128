<template>
  <page-layout>
    <span slot="header-name">{{ $tf('counter_records') }}</span>
    <table-navigation slot="header-content-actions" :state="state"></table-navigation>
    <CounterRecordsTable slot="content" :state="state" />
    <table-navigation slot="footer-content-navigation" :state="state"></table-navigation>
  </page-layout>
</template>

<script>
import PageLayout from '@/components/page/layout';
import TableNavigation from '@/components/tables/navigation.vue';
import CounterRecordsTable from './counter-records-table.vue';

export default {
  components: {
    PageLayout,
    TableNavigation,
    CounterRecordsTable
  },
  name: 'page-counter-records',
  computed: {
    state() {
      return this.$store.state.counter_records;
    }
  },
  mounted() {
    this.$store.dispatch(this.$store.state.counters.Action.Get);
  }
};
</script>

<style lang="stylus">
.event-reaction-content {
  margin-top: 1rem;
}

.filter--page-limit {
  display: inline-block;
  width: 5rem;
}

.tag-loading {
  cursor: none !important;
  opacity: 0.2;
}

.counters {
  justify-content: center;
}

.counter-records {
  .counter-records-table {
    .image-thumbnail-correction {
      cursor: default;
    }
  }
}
</style>
