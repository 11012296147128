import { render, staticRenderFns } from "./counter-records-table.vue?vue&type=template&id=12f64696&"
import script from "./counter-records-table.vue?vue&type=script&lang=ts&"
export * from "./counter-records-table.vue?vue&type=script&lang=ts&"
import style0 from "./counter-records-table.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../tmp/ffsecurity-ui/node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports