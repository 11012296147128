




import { Component, Prop, Vue } from 'vue-property-decorator';
import { CounterRecord } from './types';

@Component
export default class CounterRecordsTableColumnImage extends Vue {
  @Prop({ type: Object, required: true })
  readonly item!: CounterRecord;

  get classes() {
    return { 'image-thumbnail-correction': !this.item.fullframe };
  }

  displayImage(): void {
    this.item.fullframe &&
      this.$store.dispatch('showImage', {
        src: this.item.fullframe,
        faces_bbox: this.getBBoxesFromArray(this.item.faces_bbox),
        cars_bbox: this.getBBoxesFromArray(this.item.cars_bbox),
        silhouettes_bbox: this.getBBoxesFromArray(this.item.silhouettes_bbox, this.item.proximity_distances)
      });
  }

  getBBoxesFromArray(bboxes?: number[][][], distances?: number[][]) {
    const options = this.$store.state.config.counter_records?.proximity_display;
    const enabled = options?.enabled && distances?.length;
    return enabled
      ? this.$filters.getBBoxDistanceCoords(bboxes, distances, options)
      : (bboxes ?? []).map((item) => this.$filters.polyArrayToShortRect(item));
  }
}
